import React, { memo, useEffect, useRef } from "react";
import cx from "clsx";
import DesignerIcon from "~icons/fluent-mdl2/design";
import DeveloperIcon from "~icons/ph/code";
import ArrowLeftIcon from "~icons/quill/arrow-left";
import ArrowRightIcon from "~icons/quill/arrow-right";
import styles from "./hero.module.css";

type Props = {
  designerBg: React.ReactNode;
  designerCanvas: React.ReactNode;
  developerBg: React.ReactNode;
  developerConway: React.ReactNode;
};

function Hero({
  designerBg,
  designerCanvas,
  developerBg,
  developerConway,
}: Props) {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!CSS.supports("( animation-timeline: --hero )")) {
      function onScroll(evt: Event) {
        const target = evt.currentTarget as HTMLDivElement;
        const percentage =
          ((target.scrollLeft * 2) / target.scrollWidth) * 100 || 0;

        target.style.setProperty("--scroll", percentage + "%");

        if (percentage < 50) {
          target.style.setProperty("--under-half", "1");
        } else {
          target.style.removeProperty("--under-half");
        }
      }

      scrollRef.current!.addEventListener("scroll", onScroll);

      return () => {
        scrollRef.current!.removeEventListener("scroll", onScroll);
      };
    }

    // Check the Header MQ
    if (window.matchMedia("(min-width: 47.5em)").matches) {
      scrollRef.current!.scrollTo({
        left:
          (scrollRef.current!.scrollWidth - scrollRef.current!.clientWidth) / 2,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div className={styles.hero} data-theme="dark">
      <div className={styles.cnt} ref={scrollRef}>
        <div className={cx(styles.mask, styles["mask--props"])}>
          <div>
            {designerBg}
            {designerCanvas}
          </div>
          <div>
            {developerBg}
            {developerConway}
          </div>
        </div>

        <div className={styles.fixedContent}>
          <h1 className={styles.h1}>Hey, I'm Ben.</h1>
          <p className={styles.subtitle}>
            A Front-End Architect and Full-Stack developer who specializes in
            the Front-of-the-front-end all the way back.
          </p>
          <div className={styles.btnset}>
            <button
              aria-label="Scroll to the beginning"
              onClick={() =>
                scrollRef.current!.scrollTo({ left: 0, behavior: "smooth" })
              }
            >
              <DesignerIcon />
            </button>
            <button
              aria-label="Scroll to the end"
              onClick={() =>
                scrollRef.current!.scrollTo({
                  left: scrollRef.current!.scrollWidth,
                  behavior: "smooth",
                })
              }
            >
              <DeveloperIcon />
            </button>
            <span className={styles.scrolltxt}>
              <ArrowLeftIcon />
              <span>scrollable</span>
              <ArrowRightIcon />
            </span>
          </div>
        </div>

        <div className={cx(styles.cards, styles["mask--props"])}>
          <div className={styles.card}>
            <DesignerIcon />
            <h2>Designer</h2>
            <p>
              I love to design and build beautiful, functional, and accessible
              interfaces. The web is such a powerful platform for expression and
              creativity. Unlike other mediums, there are no guarantees on how
              your work will be visualized and you have to weigh that against a
              myriad of factors like localization, browser support, and device
              hardware. <em>What a puzzle!</em> While I pushed the limits here,
              it's important to know (and delight) your audience.{" "}
              <abbr title="User Experience">UX</abbr> Matters!
            </p>
          </div>
          <div className={styles.card}>
            <DeveloperIcon />
            <h2>Developer</h2>
            <p>
              From Fizz buzz to Conway's Game of Life, I love to code.
              JavaScript is the predominant language of the web and it features
              a rich ecosystem of new libraries, frameworks and ideas. It's a
              world that never grows old for me. Some are overwhelmed by choice
              but I see the web as an ocean of programming opportunity. Whether
              it's a simple Event Listener, Worker, Router, or a full-blown{" "}
              <abbr title="Single Page Application">SPA</abbr>, I'm always
              excited to dive in and code something new.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Hero);
